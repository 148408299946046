import { useEffect } from 'react'
import { Box, Heading, Link, Text } from '@chakra-ui/layout'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu'
import { Tooltip } from '@chakra-ui/tooltip'
import { Checkbox } from '@chakra-ui/checkbox'
import { CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBinoculars } from '@fortawesome/free-solid-svg-icons'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { getOpenSeaUrl } from 'utils/getUrl'
import { WALLETS } from 'enums/dotenv'
import { useCopy } from 'foundation/hooks/useCopy'
import { BlocksDisplay } from 'components/BlocksDisplay'

export const Selection = ({
  selectedBlocks,
  displayDao,
  onSelectDao,
  onSelect,
  newWallets,
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { copyText, copied } = useCopy()

  useEffect(() => {
    if (isOpen && selectedBlocks.length === 0) {
      onClose()
    }
  }, [isOpen, onClose, selectedBlocks.length])
  return (
    <Box
      mt="10"
      display={'flex'}
      flexWrap={'wrap-reverse'}
      alignItems={'center'}
      justifyContent={'space-between'}
      px="5"
    >
      <Box maxW={{ base: '100%', lg: '60%' }} mt={{ base: 5, lg: 0 }}>
        <Box display={'flex'} alignItems={'center'} mb="2">
          <Heading size="md">
            Selected blocks:{' '}
            {selectedBlocks.map((id, i) => (
              <span key={id}>
                <Link
                  href={getOpenSeaUrl(id, 'blocks')}
                  target={'_blank'}
                  fontWeight={'bold'}
                >
                  {id}
                </Link>
                {i < selectedBlocks.length - 1 ? ', ' : ''}
              </span>
            ))}
          </Heading>
          {selectedBlocks.length > 0 && (
            <Box flex="1" minW="110px">
              <Tooltip
                hasArrow
                gutter={20}
                label={'Check out the selected Blocks'}
                closeOnClick={false}
              >
                <Link onClick={onOpen} ml="2">
                  <FontAwesomeIcon icon={faBinoculars} size="sm" />
                </Link>
              </Tooltip>
              <Tooltip label={'Open on Playground'} hasArrow gutter={20}>
                <Link
                  href={`https://thepanels.art/playground?blocks=${selectedBlocks.join(
                    ','
                  )}&panelSize=3x3`}
                  isExternal
                  ml="2"
                >
                  <ExternalLinkIcon w="4" />
                </Link>
              </Tooltip>
              <Tooltip
                hasArrow
                gutter={20}
                label={copied ? 'Copied!' : 'Click to copy IDs'}
                closeOnClick={false}
              >
                <Link
                  onClick={() => {
                    copyText(selectedBlocks.join(', '))
                  }}
                  isExternal
                  ml="2"
                >
                  <CopyIcon w="4" />
                </Link>
              </Tooltip>
            </Box>
          )}

          <Modal isOpen={isOpen} onClose={onClose} size="6xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>SELECTED BLOCKS</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <BlocksDisplay
                  blocks={selectedBlocks}
                  onSelect={onSelect}
                  selectedBlocks={selectedBlocks}
                  preview
                />
              </ModalBody>

              <ModalFooter>
                <Button onClick={onClose}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
        <Text fontSize="md">
          Click on the blocks below to see their ids and click on ids to see on
          OpenSea
        </Text>
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyItems={'center'}>
        <Menu closeOnSelect={false} placement={'bottom-end'} matchWidth>
          <MenuButton as={Button}>Select the Blocks' accounts</MenuButton>
          <MenuList zIndex={4}>
            {[...newWallets, ...WALLETS].map((dao) => (
              <MenuItem key={dao.id} p={0} m={0}>
                <Checkbox
                  isChecked={displayDao.includes(dao.id)}
                  onChange={onSelectDao(dao.id)}
                  colorScheme={'gray'}
                  w="100%"
                  h="100%"
                  px="3"
                  py="2"
                >
                  {dao.name}
                </Checkbox>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        {children}
      </Box>
    </Box>
  )
}
