import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Box } from '@chakra-ui/layout'
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  useToast,
  Stack,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import FocusLock from 'react-focus-lock'
import { COLUMN_SIZE, WALLETS } from 'enums/dotenv'
import { fetchUserBlocksTokens } from 'contracts/blocksCore'
import EthersContext from 'context/EthersContext'

import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { Selection } from 'components/Selection'
import { DisplayDaos } from 'components/DisplayDaos'

const TextInput = forwardRef((props, ref) => {
  return (
    <FormControl>
      <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
      <Input ref={ref} id={props.id} {...props} />
    </FormControl>
  )
})

// 2. Create the form
const Form = ({
  firstFieldRef,
  onCancel,
  isDisabled = true,
  onChange,
  onSave,
  values,
}) => {
  return (
    <Stack spacing={4}>
      <TextInput
        label="Name"
        id="name"
        ref={firstFieldRef}
        placeholder="Arod"
        onChange={onChange('name')}
        value={values.name}
      />
      <TextInput
        label="Wallet address"
        id="address"
        placeholder="0x13...7Dc"
        onChange={onChange('address')}
        value={values.address}
      />
      <ButtonGroup d="flex" justifyContent="flex-end">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button isDisabled={isDisabled} onClick={onSave}>
          Save
        </Button>
      </ButtonGroup>
    </Stack>
  )
}

let toastIsOpened = false
const BLOCKS_SELECTED_LIMIT = 250
const initialValues = { name: '', address: '' }

function Home() {
  const { blocksCoreContract } = useContext(EthersContext)
  const [wallletsBlocks, setWallletsBlocks] = useState(WALLETS)
  const [selectedBlocks, setSelectedBlocks] = useState([])
  const [displayDao, setDisplayDao] = useState([])
  const [newWallets, setNewWallets] = useState([WALLETS[0]])
  const [values, setValues] = useState(initialValues)
  const { onOpen, onClose, isOpen } = useDisclosure()
  const firstFieldRef = useRef(null)
  const toast = useToast()

  const reachLimitBlocks = useCallback(() => {
    if (toastIsOpened) return
    toastIsOpened = true
    toast({
      title: 'Selected Blocks limit reached out',
      description: `Limit of selected blocks are ${BLOCKS_SELECTED_LIMIT} to keep the good user experience here and in the playground`,
      status: 'info',
      duration: 8000,
      isClosable: true,
      position: 'bottom-right',
      onCloseComplete: () => {
        toastIsOpened = false
      },
    })
  }, [toast])

  const onSelect = useCallback(
    (id) => () => {
      setSelectedBlocks((blocks) => {
        if (blocks.includes(id)) {
          return blocks.filter((blockId) => blockId !== id)
        }

        const newBlocks = [...blocks, id]
        if (newBlocks.length > BLOCKS_SELECTED_LIMIT) {
          reachLimitBlocks()
          return blocks
        }
        return newBlocks
      })
    },
    [reachLimitBlocks]
  )
  const onChangeInput = useCallback(
    (name) => (e) => {
      setValues((state) => ({
        ...state,
        [name]: e.target.value,
      }))
    },
    []
  )

  const onSelectAll = useCallback(
    (daoId) => () => {
      setSelectedBlocks((blocks) => {
        const dao = wallletsBlocks.find(({ id }) => id === daoId)
        if (!dao) return blocks
        const blocksToAdd = dao.blocks.filter((id) => !blocks.includes(id))
        const newBlocks = [...blocks, ...blocksToAdd]
        if (newBlocks.length > BLOCKS_SELECTED_LIMIT) {
          reachLimitBlocks()
          return blocks
        }
        return newBlocks
      })
    },
    [reachLimitBlocks, wallletsBlocks]
  )

  const onDeselectAll = useCallback(
    (daoId) => () => {
      setSelectedBlocks((blocks) => {
        const dao = wallletsBlocks.find(({ id }) => id === daoId)
        if (!dao) return blocks
        const newBlocks = blocks.filter((id) => !dao.blocks.includes(id))
        return newBlocks
      })
    },
    [wallletsBlocks]
  )

  const getBlocks = useCallback(
    async (daoId) => {
      try {
        const dao = [...newWallets, ...WALLETS].find(
          (wallet) => wallet.id === daoId
        )
        if (!dao || dao.blocks.length > 0) return

        const newDao = await fetchUserBlocksTokens(
          blocksCoreContract,
          dao.id
        ).then((result) => ({ ...dao, blocks: result }))

        setWallletsBlocks((daos) => {
          return daos.map((currentDao) => {
            if (currentDao.id === dao.id) {
              return newDao
            }
            return currentDao
          })
        })
      } catch (error) {
        console.log('error getting dao blocks', daoId, error)
      }
    },
    [blocksCoreContract, newWallets]
  )
  const onSelectDao = useCallback(
    (id, forceLoad) => (e) => {
      setDisplayDao((daos) => {
        const checked = e?.target?.checked || forceLoad

        if (checked) {
          getBlocks(id)

          return !daos.includes(id) ? [...daos, id] : daos
        }

        return daos.filter((daoId) => daoId !== id)
      })
    },
    [getBlocks]
  )

  const onSave = useCallback(() => {
    const wallet = {
      name: values.name,
      id: values.address,
      twitter: null,
      twitterName: null,
      blocks: [],
      originalBlocks: null,
    }
    setWallletsBlocks((state) => [wallet, ...state])
    setNewWallets((state) => [wallet, ...state])
    onClose()
    setValues(initialValues)
  }, [onClose, values.address, values.name])

  useEffect(() => {
    if (newWallets.length > 0) {
      onSelectDao(newWallets[0].id, true)()
    }
  }, [newWallets, onSelectDao])

  return (
    <>
      <Box minH={'95vh'} w="full">
        <Box mb="2">
          <Header />
          <Selection
            selectedBlocks={selectedBlocks}
            displayDao={displayDao}
            onSelectDao={onSelectDao}
            onSelect={onSelect}
            newWallets={newWallets}
          >
            <Box ml={3}>
              <Popover
                isOpen={isOpen}
                initialFocusRef={firstFieldRef}
                onOpen={onOpen}
                onClose={onClose}
                placement="right"
                closeOnBlur={false}
              >
                <PopoverTrigger>
                  <IconButton
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    variant={'outline'}
                  />
                </PopoverTrigger>
                <PopoverContent p={5}>
                  <FocusLock returnFocus persistentFocus={false}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <Form
                      firstFieldRef={firstFieldRef}
                      onCancel={onClose}
                      onSave={onSave}
                      isDisabled={
                        values.name.length === 0 || values.address.length === 0
                      }
                      onChange={onChangeInput}
                      values={values}
                    />
                  </FocusLock>
                </PopoverContent>
              </Popover>
            </Box>
          </Selection>
        </Box>
        <Box w="full" overflowY={'hidden'} maxH="80vh">
          <Box
            display={'flex'}
            w={COLUMN_SIZE * displayDao.length}
            flexDir={'column'}
          >
            <Box display={'flex'}>
              <DisplayDaos
                displayDao={displayDao}
                wallletsBlocks={wallletsBlocks}
                onSelectAll={onSelectAll}
                onDeselectAll={onDeselectAll}
                onSelect={onSelect}
                selectedBlocks={selectedBlocks}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  )
}

export default Home
