import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import '@fontsource/roboto/latin.css'
import theme from 'foundation/theme.fonts'
import { PUBLIC_URL } from 'enums/dotenv'

import Home from '../Home'

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router basename={PUBLIC_URL}>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </ChakraProvider>
  )
}
