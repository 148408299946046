import { Box, Heading, Link, Text } from '@chakra-ui/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Tooltip } from '@chakra-ui/tooltip'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { COLUMN_SIZE, WALLETS } from 'enums/dotenv'
import { getRandomInt } from 'utils/random'
import { BlocksDisplay } from 'components/BlocksDisplay'

const getRandomMessage = (ids, daoTT) => {
  const idsText = ids.map((id) => `#${id}`).join(', ')
  const messages = [
    `Let's trade! I'm interested in Blocks ${idsText}. Are you up to @${daoTT}?`,
    `I invoke @${daoTT} to trade Blocks ${idsText}! Make your offer!`,
    `We are interested in trading for ${idsText}. See our collection at arodundef.github.io/panels-contest, and let's negotiate @${daoTT}`,
  ]
  const messageIndex = getRandomInt(0, messages.length - 1)
  return encodeURIComponent(messages[messageIndex])
}

export const DisplayDaos = ({
  displayDao,
  wallletsBlocks,
  onSelectAll,
  onDeselectAll,
  onSelect,
  selectedBlocks,
}) =>
  displayDao.map((daoId) => {
    const dao = wallletsBlocks.find((wallet) => wallet.id === daoId)
    if (!dao) return <></>
    const DaoSelectedBlocks = selectedBlocks.filter((id) =>
      dao.blocks.includes(id)
    )
    const tweetText = getRandomMessage(DaoSelectedBlocks, dao.twitterName)

    return (
      <Box
        w={`${COLUMN_SIZE}px`}
        key={dao.name}
        mx="3"
        overflowY={'scroll'}
        maxH={'74vh'}
      >
        <Box
          position="sticky"
          top="0"
          zIndex="3"
          bgColor={'whiteAlpha.900'}
          py="4"
          textAlign={'center'}
        >
          <Heading size={'md'} mb="2" position={'relative'}>
            <Link
              href={`https://opensea.io/${dao.id}`}
              isExternal
              target={'_blank'}
              referrerPolicy="no-referrer"
            >
              {dao.name}
            </Link>
            {dao.twitter && (
              <Link href={dao.twitter} isExternal ml="2">
                <FontAwesomeIcon icon={faTwitter} />
              </Link>
            )}
            {!dao.twitter && (
              <Tooltip label={'Open on OpenSea'} hasArrow>
                <Link
                  href={`https://opensea.io/${dao.id}`}
                  isExternal
                  ml="2"
                  position={'absolute'}
                  mt={'-2px'}
                >
                  <ExternalLinkIcon w="4" />
                </Link>
              </Tooltip>
            )}
          </Heading>
          <Text>
            <Link onClick={onSelectAll(dao.id)}>Select all</Link>
            {' | '}
            <Link onClick={onDeselectAll(dao.id)}>Deselect all</Link>
          </Text>
          <Text>
            Blocks selected: {DaoSelectedBlocks.length}
            {dao.twitter &&
              dao.twitter !== WALLETS[0].twitter &&
              DaoSelectedBlocks.length > 0 && (
                <Link
                  href={`https://twitter.com/intent/tweet?text=${tweetText}&url=https://arodundef.github.io/panels-contest&hashtags=panelscontest`}
                  target={'_blank'}
                  referrerPolicy="no-referrer"
                  ml="2"
                  fontStyle={'italic'}
                  color={'blackAlpha.500'}
                >
                  Ask to trade <FontAwesomeIcon icon={faTwitter} size="xs" />
                </Link>
              )}
            {dao.twitter === WALLETS[0].twitter && (
              <Link
                href={'https://trade-blocks.thearod.dev/'}
                target={'_blank'}
                referrerPolicy="no-referrer"
                ml="2"
                fontStyle={'italic'}
                fontWeight={'bold'}
              >
                Trade it on Trade Blocks Tools!
              </Link>
            )}
          </Text>
        </Box>
        <BlocksDisplay
          blocks={dao.blocks}
          onSelect={onSelect}
          selectedBlocks={selectedBlocks}
          originalBlocks={dao.originalBlocks}
        />
      </Box>
    )
  })
