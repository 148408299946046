import { Box, Heading, Link, Text } from '@chakra-ui/layout'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faDonate } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from '@chakra-ui/tooltip'
import { Button } from '@chakra-ui/button'
import { CopyIcon } from '@chakra-ui/icons'
import { useCopy } from 'foundation/hooks/useCopy'

export const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { copyText, copied } = useCopy()
  return (
    <Box
      mt="0"
      position="sticky"
      top="0"
      zIndex="4"
      display={'flex'}
      justifyContent={'space-between'}
      flexWrap={'wrap'}
      bgColor={'black'}
      p="5"
      color={'white'}
    >
      <Box>
        <Heading size="md">Panels Contest Dashboard</Heading>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <Tooltip label={'Follow me on Twitter!'}>
          <Link href="https://twitter.com/Arod_tt" isExternal ml="5">
            <FontAwesomeIcon icon={faTwitter} />
          </Link>
        </Tooltip>
        <Tooltip label={'Issues, suggestions, questions? Tell me!'}>
          <Link
            href="https://github.com/arodundef/arod/discussions/1"
            isExternal
            ml="5"
          >
            <FontAwesomeIcon icon={faGithub} />
          </Link>
        </Tooltip>
        <Tooltip
          label={'Donate to support new features and projects'}
          placement="left-start"
        >
          <Link onClick={onOpen} ml="5">
            <FontAwesomeIcon icon={faDonate} />{' '}
            <Text as="span" ml="2">
              Donate
            </Text>
          </Link>
        </Tooltip>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Thank you for your support!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                Liked this project and want to support more features and
                projects like this? <br />
                <br />
                Donate any amount to{' '}
              </Text>
              <Tooltip
                label={copied ? 'Copied!' : 'Click to copy'}
                closeOnClick={false}
                hasArrow
                gutter={20}
              >
                <Text
                  as="span"
                  _hover={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    copyText('0x13d735A4D5E966b8F7B19Fc2f476BfC25c0fc7Dc')
                  }}
                  fontWeight={'bold'}
                >
                  0x13d735A4D5E966b8F7B19Fc2f476BfC25c0fc7Dc <CopyIcon />
                </Text>
              </Tooltip>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  )
}
