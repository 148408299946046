import { providers } from 'ethers'

const onChangeNetwork = (newNetwork, oldNetwork) => {
  if (oldNetwork) {
    window.location.reload()
  }
}

export class Provider {
  constructor() {
    this.provider = null
  }
  async initialize() {
    try {
      this.provider = new providers.InfuraProvider()
      console.log(this.provider)
      this.provider
        .on('network', onChangeNetwork)
        .on('chainChanged', onChangeNetwork)
        .on('accountsChanged', () => {
          window.location.reload()
        })
    } catch (error) {
      console.log('Error on initialize provider', error)
    }
    return this
  }
  get() {
    return this.provider
  }
}
