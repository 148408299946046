import { Box, Link, Text } from '@chakra-ui/layout'

export const Footer = () => (
  <Box
    as="footer"
    bgColor={'blackAlpha.700'}
    w="screen"
    px="5"
    position="sticky"
    bottom="0"
    zIndex="2"
    h="5vh"
    display={'flex'}
    alignItems={'center'}
    justifyContent={'center'}
  >
    <Text color={'white'}>
      Made with ❤️ by{' '}
      <Link
        href="https://twitter.com/Arod_tt"
        target={'_blank'}
        textDecor={'underline'}
      >
        Arod
      </Link>{' '}
      to the community
    </Text>
  </Box>
)
