import { Box, Link, Text } from '@chakra-ui/layout'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { Image } from '@chakra-ui/image'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react'
import { BLOCKS_URL } from 'enums/dotenv'
import { getOpenSeaUrl } from 'utils/getUrl'
import { useState } from 'react'

export const BlocksDisplay = ({
  blocks,
  onSelect,
  selectedBlocks,
  preview = false,
  originalBlocks,
}) => {
  const [image, setImage] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
      {blocks.map((blockId) => (
        <Box
          key={blockId}
          position={'relative'}
          w="25%"
          m="2"
          _hover={{ cursor: 'pointer' }}
          border={
            originalBlocks &&
            (blockId >= originalBlocks[0] && blockId <= originalBlocks[1]
              ? 'none'
              : '4px solid red')
          }
        >
          {preview && <Text textAlign={'center'}>#{blockId}</Text>}
          <Image
            onClick={
              preview
                ? () => {
                    setImage(blockId)
                    onOpen()
                  }
                : onSelect(blockId)
            }
            key={blockId}
            src={`${BLOCKS_URL}/${blockId}_ps${preview ? 0 : 4}.png`}
            alt={blockId}
          />
          {!preview && selectedBlocks.includes(blockId) && (
            <Box
              bgColor={'blackAlpha.600'}
              position={'absolute'}
              left="0"
              right="0"
              top="0"
              bottom="0"
              zIndex="2"
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              onClick={onSelect(blockId)}
            >
              <CheckCircleIcon color={'whiteAlpha.800'} boxSize="2em" />
            </Box>
          )}
          {preview && (
            <Box
              textAlign={'center'}
              display={'flex'}
              justifyContent={'space-between'}
              px="1"
              fontSize={'sm'}
            >
              <Link href={getOpenSeaUrl(blockId, 'blocks')} target={'_blank'}>
                OpenSea
              </Link>
              <Link onClick={onSelect(blockId)}>Remove Block</Link>
            </Box>
          )}
        </Box>
      ))}
      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>#{image}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={`${BLOCKS_URL}/${image}_ps0.png`} alt={image} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
