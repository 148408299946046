import { ethers } from 'ethers'
import { BLOCKS_CORE_ADDRESS } from 'enums/dotenv'
import _TilesBlocksCore from 'contracts/build/TilesBlocksCore.json'

export class BlocksCoreContract {
  constructor(signer) {
    this.contract = new ethers.Contract(
      BLOCKS_CORE_ADDRESS,
      _TilesBlocksCore.abi,
      signer
    )

    return this.contract
  }
}

export async function fetchUserBlocksTokens(contract, userAddress) {
  if (!userAddress) return []
  try {
    const getTokenPromises = []
    const totalBlocks = parseInt(await contract?.balanceOf(userAddress), 10)

    for (let i = totalBlocks - 1; i >= 0; i--) {
      getTokenPromises.push(
        contract
          .tokenOfOwnerByIndex(userAddress, i)
          .then((token) => parseInt(token, 10))
      )
    }
    const tokens = await Promise.all(getTokenPromises)

    return tokens
  } catch (err) {
    console.log('fetchUserBlocksTokens error: ', err)
    return []
  }
}
