import { createContext, useContext, useEffect, useState } from 'react'
import { BlocksCoreContract } from 'contracts/blocksCore'
import { Provider } from 'contracts/provider'
import GlobalContext from 'context/GlobalContext'

const DEFAULT_CONTEXT = {
  provider: null,
  providerInstance: null,
  blocksCoreContract: null,
  userAddress: '',
  refreshData: () => {},
}

const EthersContext = createContext(DEFAULT_CONTEXT)

let providerInstance, provider, blocksCoreContract

const getEtherInstances = async (ethIsEnabled) => {
  if (ethIsEnabled && !provider) {
    try {
      providerInstance = new Provider()
      await providerInstance.initialize()
      provider = providerInstance.get()
      blocksCoreContract = new BlocksCoreContract(provider)
    } catch (error) {
      console.log('Error on getEtherInstances', error)
    }
  }

  return {
    providerInstance,
    provider,
    blocksCoreContract,
  }
}

export const EthersContextProvider = ({ children }) => {
  const { ethIsEnabled } = useContext(GlobalContext)
  const [providerInstance, setProviderInstance] = useState(
    DEFAULT_CONTEXT.providerInstance
  )
  const [provider, setProvider] = useState(DEFAULT_CONTEXT.provider)
  const [blocksCoreContract, setBlocksCoreContract] = useState(
    DEFAULT_CONTEXT.blocksCoreContract
  )

  useEffect(() => {
    if (!ethIsEnabled) return
    async function setInstances() {
      const instances = await getEtherInstances(ethIsEnabled)
      setProviderInstance(instances.providerInstance)
      setProvider(instances.provider)
      setBlocksCoreContract(instances.blocksCoreContract)
    }
    setInstances()
  }, [ethIsEnabled])

  return (
    <EthersContext.Provider
      value={{
        ...DEFAULT_CONTEXT,
        providerInstance,
        provider,
        blocksCoreContract,
      }}
    >
      {children}
    </EthersContext.Provider>
  )
}

export default EthersContext
