import env from 'env-var'

const envPrefix = 'REACT_APP_'
const buildEnvName = (value) => `${envPrefix}${value}`

export const PUBLIC_URL = env.get('PUBLIC_URL').default('').asString()
export const BLOCKS_CORE_ADDRESS = env
  .get(buildEnvName('BLOCKS_CORE_ADDRESS'))
  .required()
  .asString()

export const PROVIDER_URL = env
  .get(buildEnvName('PROVIDER_URL'))
  .required()
  .asUrlString()

export const S3_URL = env.get(buildEnvName('S3_URL')).required().asUrlString()

export const BLOCKS_URL = `${S3_URL}blocks`
export const ASSETS_URL = `${S3_URL}assets`

export const COLUMN_SIZE = 500

export const WALLETS = [
  {
    name: 'Trade Blocks Tool',
    id: '0xaf06e5080d5740d0430643aE6944eeaBf4F8b43B',
    twitter: 'https://twitter.com/TheArodEth',
    twitterName: 'TheArodEth',
    blocks: [],
    originalBlocks: [0, 90000],
  },
  {
    name: 'Arihz',
    id: '0x5fe330099f53bc9081ee9026ec1ce3f3f25f120b',
    twitter: 'https://twitter.com/framergence',
    twitterName: 'framergence',
    blocks: [],
    originalBlocks: [0, 1000],
  },
  {
    name: 'BeetsDAO',
    id: '0x5875B4aAadF66d86161e6fe828729FBE1F067519',
    twitter: 'https://twitter.com/BeetsDAO',
    twitterName: 'BeetsDAO',
    blocks: [],
    originalBlocks: [263, 312],
  },
  {
    name: 'EmergenceDAO',
    id: '0x8476572326A0091FDd4688D60429c84Dfc1aC7Cf',
    twitter: 'https://twitter.com/emergencedao',
    twitterName: 'emergencedao',
    blocks: [],
    originalBlocks: [10, 59],
  },
  {
    name: 'EthBlockArt',
    id: '0x1AE0E28abF9ac188ae5fB72867639b750435984b',
    twitter: 'https://twitter.com/EthBlockArt',
    twitterName: 'EthBlockArt',
    blocks: [],
    originalBlocks: [363, 412],
  },
  {
    name: 'FingerprintsDAO',
    id: '0xf358e6b648579Bc43042B3cc7661dD5309EB06b3',
    twitter: 'https://twitter.com/FingerprintsDAO',
    twitterName: 'FingerprintsDAO',
    blocks: [],
    originalBlocks: [163, 212],
  },
  {
    name: 'FlamingoDAO',
    id: '0xca04e939a0ac0626c4a4299735e353e8dc5ef3ec',
    twitter: 'https://twitter.com/FLAMINGODAO',
    twitterName: 'FLAMINGODAO',
    blocks: [],
    originalBlocks: [60, 109],
  },
  {
    name: 'Gen Art',
    id: '0x820BF00beec4e1D6dA50Afb2C11C43FCE77a3884',
    twitter: 'https://twitter.com/CoalieBee',
    twitterName: 'CoalieBee',
    blocks: [],
    originalBlocks: [313, 362],
  },
  {
    name: 'nahfungiblebones',
    id: '0x8f635E2854df38f808146E9Acc83Fb9f5E122ED9',
    twitter: 'https://twitter.com/nahfungiblebone',
    twitterName: 'nahfungiblebone',
    blocks: [],
    originalBlocks: [513, 562],
  },
  {
    name: 'NonFungible Heroes',
    id: '0x540972477e4C66b3452bAfBf8532660204aa2b14',
    twitter: 'https://twitter.com/NFHeroes',
    twitterName: 'NFHeroes',
    blocks: [],
    originalBlocks: [413, 462],
  },
  {
    name: 'Nouns',
    id: '0x912A526852527dfB5D44c22a15B708AA493cDa6B',
    twitter: 'https://twitter.com/Noun22_',
    twitterName: 'Noun22_',
    blocks: [],
    originalBlocks: [213, 262],
  },
  {
    name: 'PleasrDAO',
    id: '0x0c3067f68aa1721f5DFDFa82F3efB68eAa203220',
    twitter: 'https://twitter.com/PleasrDAO',
    twitterName: 'PleasrDAO',
    blocks: [],
    originalBlocks: [563, 612],
  },
  {
    name: 'SharkDAO',
    id: '0xAe7f458667f1B30746354aBC3157907d9F6FD15E',
    twitter: 'https://twitter.com/sharkdao',
    twitterName: 'sharkdao',
    blocks: [],
    originalBlocks: [463, 512],
  },
  {
    name: 'SquiggleDAO',
    id: '0x56fcb471a9dde74045830439ad6612c9aad53c19',
    twitter: 'https://twitter.com/squiggledao',
    twitterName: 'squiggledao',
    blocks: [],
    originalBlocks: [113, 162],
  },
]
